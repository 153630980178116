/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @next/next/no-img-element */
import { Avatar, Box, Button, Drawer, DrawerBody, DrawerContent, DrawerOverlay, Flex, Hide, IconButton, Image, Menu, MenuButton, MenuItem, MenuList, Show } from '@chakra-ui/react';
import { Link as ILink, anonymousNavLinks, authNavLinks } from '../dashboard-layout';
import React, { RefObject, useEffect, useState } from 'react';
import { isGLO, isUK } from '../../utils/partnerTheme';
import { Auth } from '../../features/account/notifications/notification-item';
import Link from 'next/link';
import { RxHamburgerMenu } from 'react-icons/rx';
import SideNav from '../dashboard-layout/side-nav';
import { get_token } from '../../utils/CookieStore';
import { useAuth } from '../../contexts/AuthContext';
import { useIntercom } from 'react-use-intercom';
import { useRouter } from 'next/router';
const getLogoByPartner = () => {
  switch (process.env.NEXT_PUBLIC_PARTNER_CODE) {
    case 'PNFNG':
      return '/images/logom-pnf.svg';
    case 'MTNNG':
      return '/images/nav-partner-logo.svg';
    case 'AIRNG':
      return '/images/air-partner-logo.svg';
    case 'GLONG':
      return '/images/glo_landing_img.svg';
    default:
      return '/images/logom-pnf.svg';
  }
};
interface Props {
  navigate?: (ref: RefObject<HTMLDivElement>) => void;
  buyPlanRef?: RefObject<HTMLDivElement>;
  requestFixRef?: RefObject<HTMLDivElement>;
}
const Navbar = ({
  navigate,
  buyPlanRef,
  requestFixRef
}: Props) => {
  const token = get_token();
  const {
    logout,
    user
  } = useAuth() as Auth;
  const router = useRouter();
  const [isOpen, setIsOpen] = useState(false);
  const {
    show
  } = useIntercom();
  const handleResize = () => {
    if (window.innerWidth >= 768 && isOpen) {
      setIsOpen(false);
    }
  };
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize, isOpen]);
  return <Box className="navbar-wrap" data-sentry-element="Box" data-sentry-component="Navbar" data-sentry-source-file="navbar.tsx">
      <Box className="navbar-wrapper-3" data-sentry-element="Box" data-sentry-source-file="navbar.tsx">
        <Box className="container---main-2" data-sentry-element="Box" data-sentry-source-file="navbar.tsx">
          <Box data-animation="default" data-collapse="medium" data-duration="400" data-easing="ease" data-easing2="ease" role="banner" className="navbar w-nav" data-sentry-element="Box" data-sentry-source-file="navbar.tsx">
           {!isGLO && <Box className="navbar-row">
              <Link href="/" aria-current="page" className="w-inline-block w--current">
                <Image src={getLogoByPartner()} loading="eager" width={[100, 150]} height="28" alt="Logo" className="navbar-logo" />
              </Link>

              <Hide below="lg">
                <Flex as="nav" role="navigation">
                  {!isUK && <Box cursor="pointer" onClick={() => buyPlanRef ? navigate?.(buyPlanRef) : router.push('/device-care/plan')} data-hover="true" data-delay="200" className="nav-link-2 w-dropdown">
                      <Box className="dropdown-toggle-2 w-dropdown-toggle">
                        <Box className="text-block-50" fontWeight="normal">
                          Protect my device
                        </Box>
                      </Box>
                    </Box>}

                  <Box cursor="pointer" onClick={() => requestFixRef ? navigate?.(requestFixRef) : router.push('/request-fix/category')} data-hover="true" data-delay="200" className="nav-link-2 w-dropdown">
                    <Box className="dropdown-toggle-2 w-dropdown-toggle">
                      <Box className="text-block-50" fontWeight="normal">
                        Request a fix
                      </Box>
                    </Box>
                  </Box>
                </Flex>
              </Hide>
              {!isGLO && <Box className="nav-right-contents" ml="auto">
                  {!token && <>
                      <Box className="nav-button-wrapper">
                        <Box className=" w-dropdown">
                          <Link href="/register" className="small w-inline-block">
                            <Box className="">Register</Box>
                          </Link>
                        </Box>
                      </Box>
                      <Box className="nav-button-wrapper">
                        <Link href="/login" className="button-4 small bg-gray-b w-inline-block">
                          <Box className="button-text-2">Login</Box>
                          <Box className="button-effect-2 bg-white"></Box>
                        </Link>
                      </Box>
                    </>}

                  {!!token && <Show above="sm">
                      <Menu>
                        <MenuButton as={IconButton} variant="ghost">
                          <Avatar bg="primary" color="white" name={`${user?.first_name} ${user?.last_name}`} />
                        </MenuButton>
                        <MenuList>
                          {Object.values(authNavLinks).map(link => {
                      if (link.label === 'Message') {
                        return <MenuItem key={link.url} onClick={show}>
                                  {link.label}
                                </MenuItem>;
                      }
                      return <MenuItem key={link.url} href={String(link.url)} as={Link} fontWeight="normal">
                                {link.label}
                              </MenuItem>;
                    })}

                          <MenuItem onClick={() => logout()}>Logout</MenuItem>
                        </MenuList>
                      </Menu>
                    </Show>}
                  <Show below="md">
                    <Button onClick={() => setIsOpen(!isOpen)} zIndex="100000" bgColor="transparent" _active={{
                  bg: 'transparent',
                  outline: 'none'
                }} _hover={{
                  bg: 'transparent',
                  outline: 'none'
                }} aria-label="hamburger">
                      <Show below="md">
                        <RxHamburgerMenu aria-label="hamburger" />
                      </Show>
                    </Button>
                    <Drawer isOpen={isOpen} onClose={() => setIsOpen(false)}>
                      <DrawerOverlay />
                      <DrawerContent w="280px">
                        <DrawerBody>
                          <SideNav links={token ? Object.values(authNavLinks) as ILink[] : Object.values(anonymousNavLinks) as ILink[]} />
                        </DrawerBody>
                      </DrawerContent>
                    </Drawer>
                  </Show>
                </Box>}
            </Box>}
          </Box>
        </Box>
        <img src="images/icon-cross.svg" loading="lazy" width="25" height="24" alt="Close Mobile Menu" className="close-mobile-menu" />
      </Box>
    </Box>;
};
export default Navbar;