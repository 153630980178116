import { Box, Button, Heading, Link, Stack, Text } from '@chakra-ui/react';
import React from 'react';
import Navbar from '../components/homepage/navbar';
const ErrorPage = () => {
  return <Box data-sentry-element="Box" data-sentry-component="ErrorPage" data-sentry-source-file="404.tsx">
      <Navbar data-sentry-element="Navbar" data-sentry-source-file="404.tsx" />
      <Stack gap={2} alignItems="center" justifyContent="center" w="90vw" h="80vh" data-sentry-element="Stack" data-sentry-source-file="404.tsx">
        <Heading mb={0} data-sentry-element="Heading" data-sentry-source-file="404.tsx">Error 404</Heading>
        <Text data-sentry-element="Text" data-sentry-source-file="404.tsx">Page not found!</Text>
        <Link href="/" data-sentry-element="Link" data-sentry-source-file="404.tsx">
          <Button data-sentry-element="Button" data-sentry-source-file="404.tsx">Go home</Button>
        </Link>
      </Stack>
    </Box>;
};
export async function getStaticProps() {
  return {
    props: {}
  };
}
export default ErrorPage;